// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cft-special-offer-js": () => import("../src/pages/cft-special-offer.js" /* webpackChunkName: "component---src-pages-cft-special-offer-js" */),
  "component---src-pages-contacts-js": () => import("../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-forgot-password-js": () => import("../src/pages/forgot_password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-givental-js": () => import("../src/pages/givental.js" /* webpackChunkName: "component---src-pages-givental-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invite-js": () => import("../src/pages/invite.js" /* webpackChunkName: "component---src-pages-invite-js" */),
  "component---src-pages-linguotype-js": () => import("../src/pages/linguotype.js" /* webpackChunkName: "component---src-pages-linguotype-js" */),
  "component---src-pages-login-js": () => import("../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-methodology-js": () => import("../src/pages/methodology.js" /* webpackChunkName: "component---src-pages-methodology-js" */),
  "component---src-pages-offer-js": () => import("../src/pages/offer.js" /* webpackChunkName: "component---src-pages-offer-js" */),
  "component---src-pages-privacy-policy-js": () => import("../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-promo-js": () => import("../src/pages/promo.js" /* webpackChunkName: "component---src-pages-promo-js" */),
  "component---src-pages-publications-js": () => import("../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-reset-password-js": () => import("../src/pages/reset_password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-sberbank-special-offer-js": () => import("../src/pages/sberbank-special-offer.js" /* webpackChunkName: "component---src-pages-sberbank-special-offer-js" */)
}

