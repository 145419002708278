import { isClientManager } from '@kespa/user-roles';

export const getImgUrl = url => `/images/${url}`;

export const goToClassroomOrJournal = user => {
  if (process.env.NODE_ENV === 'development') {
    alert(
      `Вы авторизованы как ${user.firstName} ${user.lastName} (${user.email})`
    );

    return;
  }

  if (isClientManager(user)) {
    window.location.href = process.env.GATSBY_JOURNAL_URL;
  } else {
    window.location.href = process.env.GATSBY_CLASSROOM_URL;
  }
};

export const getTokenFromLocation = ({ search }) => {
  const match = search.match(/token=([^&]+)/);

  if (!match) {
    return '';
  }

  return match[1];
};
