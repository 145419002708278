import axios from 'axios';

import { goToClassroomOrJournal } from './src/helpers';

export const onRouteUpdate = ({ location: { hash, pathname } }) => {
  if (hash) {
    window.setTimeout(() => {
      const el = document.querySelector(hash);

      if (el) {
        return window.scrollTo(0, el.offsetTop);
      }

      return false;
    });
  }

  if (pathname.indexOf('/login') === 0) {
    axios.get('/api/auth/me').then(({ data: user }) => {
      if (user && user.email) {
        return goToClassroomOrJournal(user);
      }
    });
  }
};
